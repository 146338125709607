import React from 'react'

export default function InfoPartnership() {
    
  return (
    <section className='infoPartnership'>
        <h3 className="infoPartnership__title">Партнерство с Good day это</h3>
        <div className="infoPartnership__content-box">
            <div className="infoPartnership__item">
                <h4 className="infoPartnership__item-title">Стабильный поток новых клиентов</h4>
                <p className="infoPartnership__item-text">
                    Привлечение новых клиентов - легкая 
                    задача для Good Day.  
                    Благодаря многочисленной  
                    клиентской базе Good Day, а также  
                    активному продвижению акций  
                    партнеров, обеспечивается широкий  
                    охват акции. О вашей акции узнает  
                    каждый!                
                </p>
            </div>
            <div className="infoPartnership__item">
                <h4 className="infoPartnership__item-title">PROдвижение к успеху</h4>
                <p className="infoPartnership__item-text infoPartnership__item-text_marginTop" >     
                    Good Day обладает всеми доступными 
                    маркетинговыми каналами для 
                    успешного продвижения партнёров: 
                    push-уведомления, stories в приложении, 
                    социальные сети и многое другое. Вашу 
                    акцию будет невозможно пропустить!
                </p>
            </div>
            <div className="infoPartnership__item">
                <h4 className="infoPartnership__item-title">Пользователь Good Day - ваш лояльный клиент</h4>
                <p className="infoPartnership__item-text">
                    Наши пользователи знают, что за каждым 
                    акцией стоит проверенная и 
                    качественная услуга. Пользователи Good 
                    Day готовы пробовать, потому что они 
                    нам доверяют. А мы доверяем нашим 
                    партнерам. Благодаря такому 
                    взаимодействию положительные отзывы 
                    и рекомендации привлекают все больше 
                    новых пользователей в Good Day, 
                    которые впоследствии становятся 
                    вашими постоянными клиентами.
                </p>
            </div>
        </div>
    </section>
  )
}
